.actionBlockItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.actionBlockItem {
  flex: 2;
  padding: 0 5px;
}
