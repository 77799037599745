.MonitorContainer {
  display: flex;
  flex: 1 1;
}

.MonitorTableBlock {
  width: 30%;
}

.AnotherTablesBlock {
  width: 70%;
}
