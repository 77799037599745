.Width {
  width: 100%;
  margin-bottom: 12px !important;
}

.TitleMargin > div:first-child {
  margin-bottom: 24px;
}

.ScrollForm {
  overflow: scroll;
  max-height: 620px;
}
