.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
.d-inline {
  display: inline;
}
.flex-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}
.align-items-start {
  align-items: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.m-0 {
  margin: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mr-3 {
  margin-right: 0.75rem !important;
}
.ml-3 {
  margin-left: 0.75rem !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
button {
  outline: 0 !important;
}
.disabled {
  pointer-events: none;
  filter: brightness(0.8);
}
.blur {
  opacity: 0.6;
}
.text-left {
  text-align: left;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
.w-lg {
  width: 140px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-8 {
  font-size: 8px;
}
