.CustomButton {
  width: max-content;
  padding: 5px 25px !important;
  margin-right: 25px !important;
}

.TitleRunScript {
  display: flex;
  font-family: FoxSansPro, sans-serif;
  margin: 0;
  padding-bottom: 24px;
}

.BorderNone {
  margin: 0;
}

.BorderNone td {
  border: none !important;
}

.MarginBottom {
  margin-bottom: 24px;
}

.HeadsTable {
  font-weight: 500;
  font-size: 16px;
}
